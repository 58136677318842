import {
  ArrowRightIcon,
  Box,
  Button,
  Checkbox,
  FrameworkLink,
  theme,
  Typography,
} from '@ttx/design-system';
import React, {useState} from 'react';
import {TextInput} from 'react-native';
import {useToastContext} from '../../contexts/toast-context';
import {useSubscribe} from '../../hooks/use-subscribe';
import {validateEmail} from '../../utils/regex-validation';

interface SubscriptionSectionProps {
  isMobile: boolean;
  subscribeInfo?: {
    subscribeList: number;
    subscribeTemplate: number;
  };
}

export const SubscriptionSection: React.FC<SubscriptionSectionProps> = ({
  isMobile,
  subscribeInfo,
}: SubscriptionSectionProps) => {
  const [email, setEmail] = useState('');
  const [isAgree, setIsAgree] = useState(false);
  const {onSubscribeSubmit} = useSubscribe();
  const toast = useToastContext();
  const onSubmit = () => {
    if (!email || !validateEmail(email)) {
      toast.addErrorToast('Please Enter Valid Email');
      return;
    }
    if (!isAgree) {
      toast.addErrorToast('Please accept privacy policy');
      return;
    }
    onSubscribeSubmit({
      email,
      ...subscribeInfo,
      callBack: (res) => {
        if (res) {
          setEmail('');
          setIsAgree(false);
        }
      },
    });
  };

  const buttonBg =
    subscribeInfo && Object.keys(subscribeInfo).length
      ? {
          backgroundColor: 'var(--studio-primaryButton)',
          textColor: 'var(--studio-primaryButtonText)',
        }
      : {};

  return (
    <Box alignItems="center" height="456px">
      <Box marginTop="fifteen" width={isMobile ? '100%' : '500px'}>
        <Box alignItems="center" marginBottom="four">
          <Typography
            lineHeight="biglead"
            fontWeight="regular"
            color="textDefault"
            fontSize={['h2', 'heroSmall']}
          >
            Stay in the know
          </Typography>
        </Box>
        <Box marginTop="four">
          <Typography
            lineHeight="biglead"
            fontWeight="regular"
            fontFamily="abc"
            color="textDefault"
            textAlign="center"
            fontSize="lead"
          >
            Sign up to receive email updates on new drops, upcoming projects,
            and exclusive content.
          </Typography>
        </Box>
        <Box width="100%" marginTop="four">
          <Typography
            lineHeight="biglead"
            fontWeight="regular"
            fontFamily="abc"
            color="textDefault"
            fontSize="s"
          >
            Email
          </Typography>
          <TextInput
            onChangeText={(e) => setEmail(e)}
            value={email}
            placeholder="Enter email address"
            placeholderTextColor={theme.colors.textLow}
            style={
              {
                fontFamily: theme.fonts.abc,
                padding: theme.space.two,
                color: theme.colors.textDefault,
                borderColor: theme.colors.outlines,
                borderWidth: theme.borderWidths.one,
                borderStyle: theme.borderStyles.solid,
                marginTop: '5px',
              } as any
            }
          />
        </Box>
        <Box marginTop="four">
          <Checkbox
            label="By subscribing I agree to the TokenTraxx privacy policy"
            checked={isAgree}
            onCheckboxPress={() => setIsAgree(!isAgree)}
          />
        </Box>
        <Box alignItems="center" marginTop="five">
          <Button
            type="primary"
            leftAccessory={<ArrowRightIcon color="textInvert" />}
            buttonStyle={{
              width: isMobile ? '100%' : '200px',
              ...buttonBg,
            }}
            onPress={onSubmit}
          >
            Subscribe
          </Button>
          <Box marginTop="five">
            <Typography textStyle="s" color="textDefault">
              Proceeds from The Sahel Initiative will be split between
              contributing artists and the UNOCHA-managed{' '}
              <FrameworkLink href="https://crisisrelief.un.org/t/west-and-central-africa">
                Regional Humanitarian Fund
              </FrameworkLink>
              for West and Central Africa which focuses on supporting
              humanitarian action in the Sahel Region.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
